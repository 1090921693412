
<template>
    <v-card rounded="lg" class="mx-auto pa-4" width="400" max-width="96vw">
        <v-card-title class="text-center d-flex flex-column">{{
            tenantName
        }}</v-card-title>
        <v-card-subtitle class="text-center">{{

}}</v-card-subtitle>

        <v-window v-model="step" reverse>
            <v-window-item :value="1">
                <v-card-text class="py-16">
                    <p>We sturen je een link naar dit email adres:</p>
                    <v-text-field outlined autofocus hide-details="auto" persistent-placeholder :label="E - mail"
                        v-model="mailTo"></v-text-field>
                </v-card-text>
                <v-card-actions class="mt-4">
                    <v-btn plain @click.stop="backToLogin">
                        Terug
                    </v-btn>
                    <v-spacer />
                    <v-btn :disabled="!mailTo" :loading="loading" color="primary" @click.once="resetPassword">
                        Link verzenden
                    </v-btn>
                </v-card-actions>
            </v-window-item>
            <v-window-item :value="2">
                <v-card-text class="py-16">
                    <p class="text-center red--text">{{ errMsg }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn plain @click.stop="retry">
                        Terug
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-window-item>
            <v-window-item :value="3">
                <v-card-text class="py-16">
                    <p class="text-center">De link is verzonden.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn plain @click.stop="backToLogin">
                        Terug
                    </v-btn>
                </v-card-actions>
            </v-window-item>
        </v-window>
    </v-card>
</template>
<script>
import { functions } from "@/services/firebase";
import { httpsCallable } from "firebase/functions";

export default {
    name: "ResetPassword",
    data: () => ({
        step: 1,
        mailTo: "",
        error: false,
        errMsg: "",
        loading: false,
        appName: process.env.VUE_APP_APP_NAME,
        tenantName: process.env.VUE_APP_TENANT_NAME,
        tenantId: process.env.VUE_APP_TENANT_ID,
    }),
    activated() {
        //Called when a kept-alive component is activated.
        this.step = 1;
        this.mailTo = this.$router.currentRoute.params.mailTo;
    },
    methods: {
        backToLogin() {
            this.$router.push({ name: "Login" });
        },
        resetPassword() {

            this.loading = true;
            const params = {
                email: this.mailTo,
                actionCodeSettings: {
                    url: process.env.VUE_APP_APP_URL
                },
                /*
                tenantId: Number(process.env.VUE_APP_TENANT_ID),
                language: this.$root.$i18n.locale
                */
            };
            const call = httpsCallable(functions, "sendPasswordResetLink");
            call(params)
                .then((response) => {
                    if (response.data.error) {
                        this.error = true;
                        this.errMsg = "Er is iets misgelopen";
                        this.step = 2;
                    } else {
                        if (response.data.link) {
                            alert(response.data.link)
                            this.error = false;
                            this.errMsg = null;
                            this.step = 3;
                        }
                    }
                })
                .catch((error) => {
                    this.error = true;
                    this.errMsg = error;
                    this.step = 2;
                })
                .finally(() => {
                    this.loading = false;
                    this.backToLogin()
                });
        },
        retry() {
            this.step = 1;
        },
    },
};
</script>
